.App{
  width: 100vw;
  height: 100vh;
  margin-top: 0;
  margin-left: 0;
  padding: 0;

  background-image: url("./static/img/order_page.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

